import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/Layout"
import { SEO } from "../components/seo"

export default function Progetti() {
  return (
    <Layout>
      <div className="presentazione">
        <div className="single-project-text">
          <h5>
            <strong>MW Architettura</strong>
            <br />
            MW Architettura Sagl è un’azienda nata nel 2022 sulla base
            dell’attività di Moreno Wildhaber che nel decennio precedente è
            cresciuta gradualmente grazie soprattutto al passaparola e alla
            conoscenza del tedesco. <br />
            Il nome scelto deriva dall’usanza nella cultura contadina di
            iscrivere in architravi, porte, o altri parti di edificio, le
            iniziali del proprio nome quando un’opera veniva portata a termine.
            Il legame con il territorio è uno dei concetti cardine
            dell’architettura che lo studio vuole proporre. La ricerca di
            soluzioni razionali, pensate e sviluppate per potere soddisfare le
            esigenze funzionali, economiche ed ecologiche senza però tralasciare
            l’estetica è prioritario in tutti progetti. L’obiettivo alla fine di
            un cantiere è gli attori coinvolti nel processo -committenza,
            artigiani, specialisti,…- siano soddisfatti del risultato e della
            collaborazione reciproca, come pure quello di mantenere buoni
            rapporti con il vicinato. <br />
            MW Architettura disegna con il programma Archicad, è iscritto
            all’Otia con il numero 7'400 e fa parte di Ticino Energia. Non
            volendo contribuire al crescente problema che delle ditte private
            assumono poteri, a volte superiori a quelli di uno stato politico,
            si rinuncia volutamente all’utilizzo di Google, Faceboock, Whatsapp
            ecc.
          </h5>
          {/* <Link to={"/"}>
            <h5>Interessi personali</h5>
          </Link> */}
        </div>

        <div
        // className="single-project-picture-landscape"
        // style={{ margin: "auto" }}
        >
          <StaticImage
            layout="constrained"
            // aspectRatio={1 / 1}
            src={"../assets/icons/mw_logo.png"}
            width={300}
            // height={300}
            alt="Alambicco"
          />
        </div>

        <div /* className="single-project-text" */>
          <a className="anchor" id="contatto"></a>
          <h5>
            <strong>MW Architettura</strong>
            <br />
            MW Architettura Sagl - 6647 Mergoscia
            <br />
            +41 79 476 07 85
            <br />
            info@mw-architettura.ch
            <br />
            Lun-Ven: 7:00 - 18:00
            <br />
            Sab-Dom: Chiuso
          </h5>
          {/* <Link to={"/"}>
            <h5>Interessi personali</h5>
          </Link> */}
        </div>
        <div>
          <StaticImage
            layout="constrained"
            aspectRatio={1 / 1}
            src={"../assets/images/Presentazione/moreno.jpeg"}
            width={300}
            alt="Alambicco"
          />
        </div>

        <div>
          <h5>
            <strong>Moreno Wildhaber</strong>
            <br />
            dipl. Arch. FH <br />
            Titolare <br />
            info@mw-architettura.ch
          </h5>
          {/* <Link to={"/"}>
            <h5>Interessi personali</h5>
          </Link> */}
          <Link to={"/presentazione/morenoCV"}>
            <h5 className="underline">Curriculum professionale</h5>
          </Link>
        </div>
        {/* <div>
          <h5 className="single-project-text">
            Moreno è il titolare e fondatore dello studio MW Architettura.La
            prima formazione di Moreno è quella di selvicoltore, professione che
            continua a svolgere a titolo accessorio insieme a quella di
            viticoltore. Aver lavorato nei boschi, svolgendo un lavoro fisico,
            gli ha permesso di imparare a ragionare al di fuori delle situazioni
            standard e a trovare velocemente soluzioni tangibili e realizzabili.
            Le discussioni con artigiani e operai si svolgono su un piano
            orizzontale fra persone orientate alla soluzione e non, come
            purtroppo spesso accade, verticalmente fra architetto (teoria) ed
            esecutore (pratica). Questa empatia e conoscenza della materia è
            molto apprezzata dalle ditte e dai committenti, che vedono in Moreno
            un professionista competente, preciso e affidabile. Negli anni ha
            sviluppato delle tecniche personali di gestione del progetto che
            esulano dalle classiche CPN/CCC ma che prevedono un maggiore dialogo
            tra le persone coinvolte.
          </h5>
          <h5>
            Moreno è attivo nella Federviti Locarno e Valli, dal 2012 è
            presidente dell’assemblea comunale di Mergoscia e dal 2020 è socio
            della società dei distillatori svizzeri. Dal 2005 al 2018 è stato
            pompiere di montagna. A livello di sport si interessa quasi solo
            all’hockey. Come gusti musicali predilige ritmi e testi allegri come
            ska, punk, rockabilly, musica popolare e in alcuni casi metal.
          </h5>
         */}
      </div>
    </Layout>
  )
}

export const Head = () => <SEO />
